import React from "react"
import styled from "styled-components"
import Logo from "./icons/Logo"

const Hero = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <HeroItems>
          <Logo />
          <HeroH1>Chauffage et Sanitaire</HeroH1>
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 30vh;
  position: relative;
  margin-top: 100px;
  padding-bottom: 20px;
  color: var(--white);

  :before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
    position: relative;
    margin-top: 100px;
  }
`

const HeroContent = styled.div`
  z-index: 3;
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);
`

const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 0;
  color: var(--white);
`

const HeroH1 = styled.h1`
  font-size: clamp(1.7rem, 4vw, 4rem);
  margin: 1rem;
  letter-spacing: 1px;
  font-weight: 600;
  padding: 1rem;
  text-transform: uppercase;

  @media (max-width: 768px) {
    padding-top: 0.1rem;
    margin: 0;
    padding: 0.5rem;
  }
`
