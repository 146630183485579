import React from "react"

export const Clock = ({ className }) => (
  <svg viewBox="0 0 180 180" style={{ width: "100px" }} className={className}>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M93.2 69.1L93.2 100.6"
    ></path>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M73.4 121.6L92.4 102.6"
    ></path>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M146.8 102.5L142.8 102.5"
    ></path>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M38 103.4L33.9 103.4"
    ></path>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M93.2 150.8L93.2 154.8"
    ></path>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M93.2 41.8L93.2 45.9"
    ></path>
    <circle
      cx="92.3"
      cy="102.5"
      r="2.3"
      fill="#EE9838"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
    ></circle>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeWidth="6"
      d="M89.6 21.9c42.1 0 76.3 34.1 76.3 76.3s-34.1 76.3-76.3 76.3-76.3-34.1-76.3-76.3 34.2-76.3 76.3-76.3z"
    ></path>
    <path
      fill="none"
      stroke="#EE9838"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth="6"
      d="M105 23V8.3c0-1.3-2.1-2.3-4.6-2.3h-18c-2.6 0-4.6 1-4.6 2.3v13.8"
    ></path>
  </svg>
)
