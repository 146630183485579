import React from "react"
import styled from "styled-components"

const Logo = ({ className }) => (
  <StyledLogo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1047.51 143.17">
    <g style={{ isolation: "isolate" }} fill="#fff">
      <path d="M96.56 25.36H62.11v115.9H33.88V25.36H0V1.91h96.56v23.45zM207.09 1.91v96.47c-.13 14.48-4.12 25.57-11.96 33.26-7.85 7.69-19.11 11.53-33.78 11.53s-26.33-3.89-34.21-11.68c-7.88-7.78-11.82-19.04-11.82-33.78V1.91h28.33v95.9c0 7.91 1.28 13.56 3.83 16.94 2.55 3.38 7.18 5.07 13.88 5.07s11.29-1.67 13.78-5.02 3.76-8.85 3.83-16.51V1.91h28.14zM274.36 90.34h-13.97v50.91h-28.14V1.91h44.88c14.1 0 24.99 3.65 32.68 10.96 7.69 7.31 11.53 17.69 11.53 31.15 0 18.5-6.73 31.46-20.19 38.86l24.4 57.04v1.34h-30.24l-20.96-50.91zm-13.97-23.45h15.98c5.61 0 9.82-1.87 12.63-5.6 2.81-3.73 4.21-8.72 4.21-14.98 0-13.97-5.46-20.96-16.37-20.96h-16.46v41.54zM344.41 141.26V1.91h43.07c14.55 0 25.6 3.24 33.16 9.71 7.56 6.48 11.34 15.97 11.34 28.47 0 7.08-1.56 13.18-4.69 18.28-3.13 5.11-7.4 8.84-12.82 11.2 6.25 1.79 11.07 5.38 14.45 10.77 3.38 5.39 5.07 12.04 5.07 19.95 0 13.53-3.72 23.73-11.15 30.62-7.43 6.89-18.2 10.34-32.3 10.34h-46.13zm28.14-81.06h14.64c11.04 0 16.56-5.71 16.56-17.13 0-6.32-1.28-10.85-3.83-13.59s-6.7-4.12-12.44-4.12h-14.93V60.2zm0 20.48v37.23h17.99c5.04 0 8.87-1.56 11.48-4.69 2.62-3.12 3.92-7.56 3.92-13.3 0-12.5-4.53-18.92-13.59-19.24h-19.81z"></path>
    </g>
    <g style={{ isolation: "isolate" }} fill="#fff">
      <path d="M714.49 25.36h-34.45v115.9h-28.23V25.36h-33.88V1.91h96.56v23.45zM806.36 80.96h-43.83v36.94h51.87v23.35h-80.01V1.91h79.82v23.45h-51.68v32.92h43.83v22.68zM928.75 94.84c-.7 15.89-5.17 27.91-13.4 36.08-8.23 8.17-19.84 12.25-34.84 12.25-15.76 0-27.83-5.18-36.22-15.55-8.39-10.37-12.58-25.15-12.58-44.36V59.81c0-19.14 4.34-33.89 13.02-44.26C853.41 5.18 865.47 0 880.91 0s26.72 4.24 34.6 12.73c7.88 8.49 12.36 20.67 13.45 36.56h-28.23c-.26-9.82-1.77-16.6-4.55-20.34s-7.86-5.6-15.26-5.6-12.86 2.63-15.98 7.9c-3.13 5.26-4.79 13.92-4.98 25.98v26.32c0 13.85 1.55 23.35 4.64 28.52 3.09 5.17 8.41 7.75 15.93 7.75s12.5-1.8 15.31-5.41c2.81-3.6 4.4-10.13 4.79-19.57h28.14zM1047.51 141.26h-28.04V81.64h-41.54v59.62h-28.14V1.91h28.14v56.37h41.54V1.91h28.04v139.35z"></path>
    </g>
    <StyledBox
      fill="#fe9d2b"
      d="M543.39 15.84C534.46 5.28 522.24 0 506.74 0s-28.07 5.38-36.94 16.13c-8.87 10.75-13.3 25.7-13.3 44.84V84.8c.13 18.44 4.66 32.78 13.59 43.02 8.93 10.24 21.21 15.36 36.85 15.36s27.96-5.17 36.8-15.5c8.84-10.34 13.25-24.85 13.25-43.54V60.31c-.13-19.08-4.66-33.89-13.59-44.45z"
    ></StyledBox>
    <StyledFire
      fill="#1a1818"
      d="M507.47 118.14s47.1-.39 20.98-58.1c0 0-5.01 12.48-7.47 12.84s7.03-28.47-14.9-46.13c0 0-.39 12.73-7.71 22.08a37.477 37.477 0 00-7.51 20.27s-6.91-7.28-6.12-13.77-23.08 63.03 22.73 62.82"
    ></StyledFire>
  </StyledLogo>
)

export default Logo

const StyledFire = styled.path`
  animation: flicker 1s 0.4s infinite alternate linear;
  transform-origin: bottom;
  transform-box: fill-box;

  @keyframes flicker {
    0% {
      transform: rotate(-1deg);
    }
    20% {
      transform: rotate(1deg);
    }
    40% {
      transform: rotate(-1deg);
    }
    60% {
      transform: rotate(1deg) scaleY(1.02);
    }
    80% {
      transform: rotate(-1deg) scaleY(0.95);
    }
    100% {
      transform: rotate(1deg);
    }
  }
`

const StyledBox = styled.path`
  animation: flicker 2s 0.5s infinite alternate linear;
  transform-origin: bottom;
  transform-box: fill-box;
  overflow: visible;
  z-index: 2;
  @keyframes flicker {
    0% {
      transform: rotate(-1deg);
    }
    20% {
      transform: rotate(1deg);
    }
    40% {
      transform: rotate(-1deg);
    }
    60% {
      transform: rotate(1deg) scaleY(1.02);
    }
    80% {
      transform: rotate(-1deg) scaleY(0.95);
    }
    100% {
      transform: rotate(1deg);
    }
  }
`

const StyledLogo = styled.svg`
  width: 96%;
  padding: 1%;
  &:not(:root) {
    overflow: visible !important;
    padding: 4%;

    @media (max-width: 768px) {
      width: 90%;
      margin: 0;
    }
  }
`
